.custom-label {
    background-color: black;
    color: white;
    font-weight: bold;
    border-radius: 8px;
    padding: 2px;
    text-align: center;
    white-space: nowrap;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.5);
    position: absolute;
    top: 45px;
    left: 50%;
    transform: translateX(-50%);
    font-size: 10px;
    white-space: nowrap;
}

.custom-label-events {
    background-color: black;
    color: white;
    font-weight: bold;
    border-radius: 8px;
    padding: 2px;
    text-align: center;
    white-space: nowrap;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.5);
    position: absolute;
    top: 40px;
    left: 50%;
    transform: translateX(-50%);
    font-size: 10px;
    white-space: nowrap;
}
.custom-label-rotate {
    background-color: black;
    color: white;
    font-weight: bold;
    border-radius: 8px;
    padding: 2px;
    text-align: center;
    white-space: nowrap;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.5);
    position: absolute;
    top: 50px; /* Ajuste para posicionar abaixo do ícone */
    left: 50%;
    transform: translateX(-50%);
    font-size: 10px;
}